import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

// const store = new Vuex.Store({
//   modules,
//   getters
// })

// export default store

export default new Vuex.Store({
  state: {
      username: '',
      k: '',
      title: '',
      isLogin: false,
  },
  mutations: {
    setToken(state, token) {
      state.k = token
    },
    setUser(state, username) {
      state.username = username
    },
    setTitle(state, title) {
      state.title = title
    },
    userStatus(state, flag) {
      state.isLogin = flag
    },
  },
  actions: {
    setUserStatus ({commit}, flag) {
      commit('userStatus', flag)
    }
  }
})
