import request from '@/utils/request'
import qs from 'qs'

export function getList(params) {
    return request({
        url: '/api/user/getList',
        method: 'post',
        params: params
    })
}

export function getSupUserList(params) {
    return request({
        url: '/api/user/getSupUserList',
        method: 'post',
        params: params
    })
}

export function add(params) {
    return request({
        url: '/api/user/add',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function update(params) {
    return request({
        url: '/api/user/update',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function getOneById(params) {
    return request({
        url: '/api/user/getOneById',
        method: 'post',
        params: params
    })
}

export function enableFalse(params) {
    return request({
        url: '/api/user/enableFalse',
        method: 'post',
        params: params
    })
}

export function addSupUser(params) {
    return request({
        url: '/api/user/addSupUser',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function updateSupUser(params) {
    return request({
        url: '/api/user/updateSupUser',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function login(params) {
    return request({
        url: '/api/user/login',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function getCorporateName(params) {
    return request({
        url: '/api/user/getCorporateName',
        method: 'post',
        params: params
    })
}

export function getQrcode(params) {
    return request({
        url: '/api/user/getQrcode',
        method: 'post',
        params: params
    })
}

/**
 * 手机号登录（免密）
 * @returns 
 */
export function loginFreePassword(params) {
    return request({
        url: '/api/loginFreePassword',
        method: 'post',
        params: params
    })
}