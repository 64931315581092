<template>

    <div class="right-menu">
      <el-dropdown class="avatar-container right-menu-item hover-effect">
          <div style="cursor: pointer;color: #9c9c9c;height: 5.2vh;padding-left: 1vw;padding-right: 1vw;">{{username}}</div>
        <el-dropdown-menu></el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
<!--        <div class="avatar-wrapper">-->
<!--          <img src="~@/assets/images/avatar.png" class="user-avatar">-->
<!--          <i class="el-icon-caret-bottom" />-->
<!--        </div>-->

<!--        <el-dropdown-menu>-->
          <div style="cursor: pointer;color: #9c9c9c;height: 5.2vh;padding-left: 1vw;padding-right: 1vw;" @click="logout">退出</div>
<!--        </el-dropdown-menu>-->
        <el-dropdown-menu></el-dropdown-menu>
      </el-dropdown>
<!--      <transition name = "fade">-->
<!--        <el-card class="profile-card" v-if="showProfile">-->
<!--          <div slot="header" class="clearfix">-->
<!--            <span><i class="el-icon-user"></i>个人信息</span>-->
<!--          </div>-->
<!--          <el-form label-position="left" label-width="120px" size="large" :model="formData" ref="ruleForm" :rules="rules" validate-on-rule-change>-->
<!--            <el-form-item label="所属机构:">-->
<!--              <el-select disabled v-model="formData.orgin_id" clearable placeholder="无">-->
<!--                <el-option :label="formData.orgin" :value="formData.orgin_id" />-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="角色:" prop="role">-->
<!--              <el-tooltip class="item" effect="dark" content="管理员具有“管理与配置”页面访问权限；职员无“管理与配置”页面访问权限。两种角色均可查看、管理所属机构以及所属机构的附属机构库房的警情。" placement="top-start">-->
<!--                <el-select v-model="formData.role"  placeholder="请选择角色" disabled>-->
<!--                  <el-option v-for="item in roleListNew" :key="item.key" :label="item.role" :value="item.key" />-->
<!--                </el-select>-->
<!--              </el-tooltip>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="姓名:" prop="name">-->
<!--              <el-input type="text" v-model="formData.name"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="用户名:" prop="username2">-->
<!--              <el-input type="text" v-model="formData.username" disabled autocomplete="off"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="密码:" prop="passward">-->
<!--              <el-input type="password" v-model="formData.passward" autocomplete="off"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="电话:" prop="mobile_phone">-->
<!--              <el-input type="text" v-model="formData.mobile_phone" ></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="邮箱:" prop="email">-->
<!--              <el-input type="text" v-model="formData.email" ></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="描述:">-->
<!--              <el-input type="textarea" rows=5 v-model="formData.description" ></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label-width="0">-->
<!--              <el-button class="comfirm" type="primary" @click="submitForm()">确定</el-button>-->
<!--              <el-button class="cancel"                 @click="showProfile=false">取消</el-button>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </el-card>-->
<!--      </transition>-->
    </div>

</template>

<script>

import Cookies from "js-cookie";
import store from '@/store'

export default {
  data(){
    var checkEmail = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (value === null || value.trim() === '') {
        callback()
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('Email格式不正确'))
        }
      }
    }
    var checkPhone = (rule,value,callback) =>{
      let reg = /^1[34578]\d{9}$/
      if (value === null || value.trim() === '') {
        callback(new Error('手机号不能为空'))
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('手机号格式不正确'))
        }
      }
    }
    var checkPassword = (rule,value,callback) =>{
      if (value === null || value.trim() === '' ) {
        if (this.formData.id == null) {
          callback(new Error('密码不能为空'))
        } else {
          callback()
        }
      } else if (value.length <3 || value.length > 10) {
        callback(new Error('密码长度 3 到 10 个字符'))
      } else {
        callback()
      }
    }
    return {
      showProfile: false,
      formData:{
        orgin_id:"", 
        role:"",
        name:"",
        username:"",
        mobile_phone:"",
        email:"",
        description:"",
      },
      roleListNew:[{key:1,role:"管理员"},{key:2,role:"职员"}],
      rules:{
        name:[
          { required: true, message: '姓名不能为空', trigger: 'blur' },
        ],
        // passward:[
        //   {  validator: checkPassword, trigger: 'blur' }
        // ],
        email:[
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile_phone:[
          { required: true, validator:checkPhone, trigger: 'blur' },
        ]
      },
      username:store.state.username
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$store.commit("setToken", "");
      this.$store.commit("setUser", "");
      sessionStorage.removeItem("k")
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    showProfileTransition(){

    },
    cancelForm(){
      this.$refs.ruleForm.resetFields();
      this.showEdit = false
    },
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let params = Object.assign({},this.formData)

          }
        });
    }
  }
}
</script>

<style scoped>
  .right-menu>>>.profile-card.fade-enter-active, .right-menu>>>.profile-card.fade-leave-active {
    transition: right 0.3s
  }
  .right-menu>>>.profile-card.fade-enter, .right-menu>>>.profile-card.fade-leave-to {
    right: -423px;
  }
</style>

<style lang="scss" scoped>
  .right-menu {
    height: 55px;
    line-height: 50px;
    float: right;

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #cccccc;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 10px;

      .avatar-wrapper {
        margin-top: 15px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
    .profile-card{
      position:absolute;
      width: 423px;
      right:0;
      bottom:0;
      z-index: 2001;
      height: calc(100vh - 65px);
      overflow-x: hidden;
      overflow-y: scroll;
      .el-select{
        width: 100%;
      }
      .el-form-item{
        .cancel {
          float: right;
          margin-top: 40px;
        }
        .comfirm {
          float: right;
          margin-top: 40px;
          width: 160px;
          margin-left: 30px;
        }
      }
      .el-card__header{
        height: 58px;
      }
      font-size: 18px;
      line-height: 18px;
    }
  }
</style>
