import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import {loginFreePassword} from '@/api/user'
import md5 from 'js-md5';

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
  {
    path:'/',
    redirect:'/business'
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/index',
    component: () => import('@/views/dashboard/index'),
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/dashboard/index'),
        name: 'center',
        meta: { title: 'center', requireAuth: true }
      }
    ]
  },
  {
    path: '/business',
    component: Layout,
    redirect: '/business/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/business/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/provider',
    component: Layout,
    redirect: '/provider/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/provider/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/log/temperature-list',
    component: Layout,
    redirect: '/log/temperature-list/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/log/temperature-list/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/log/temperature-echarts',
    component: Layout,
    redirect: '/log/temperature-echarts/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/log/temperature-echarts/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
        path: '/log/temperature-echarts-average',
        component: Layout,
        redirect: '/log/temperature-echarts-average/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/log/temperature-echarts-average/index'),
                name: 'center',
                meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
            }
        ]
    },
    {
      path: '/log/temperature-echarts-index',
      component: Layout,
      redirect: '/log/temperature-echarts-index/index',
      children: [
          {
              path: 'index',
              component: () => import('@/views/log/temperature-echarts-index/index'),
              name: 'center',
              meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
          }
      ]
  },
  {
    path: '/log/humidity-echarts-index',
    component: Layout,
    redirect: '/log/humidity-echarts-index/index',
    children: [
        {
            path: 'index',
            component: () => import('@/views/log/humidity-echarts-index/index'),
            name: 'center',
            meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
        }
    ]
  },
  {
    path: '/log/equalarm',
    component: Layout,
    redirect: '/log/equalarm/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/log/equipment-alarm/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/log/operate',
    component: Layout,
    redirect: '/log/operate/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/log/operate/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/datareport/sunburst',
    component: Layout,
    redirect: '/datareport/sunburst/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/datareport/sunburst/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/datareport/alarmTrend',
    component: Layout,
    redirect: '/datareport/alarmTrend/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/datareport/alarmTrend/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/datareport/customerGrowth',
    component: Layout,
    redirect: '/datareport/customerGrowth/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/datareport/customerGrowth/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/datareport/annualReport',
    component: Layout,
    redirect: '/datareport/annualReport/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/datareport/annualReport/index'),
        name: 'center',
        meta: { title: 'center', icon: 'documentation', affix: true, requireAuth: true }
      }
    ]
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/redirect',
    component: () => import('@/views/redirect/index'),
    hidden: true
  }

]

//页面刷新时，重新赋值token
if (sessionStorage.getItem('k')) {
  store.commit('setToken', sessionStorage.getItem('k'))
  store.commit('setUser', sessionStorage.getItem('name'))
}

window.addEventListener('beforeunload',()=>{
  sessionStorage.setItem('k',store.state.k)
  sessionStorage.setItem('name',store.state.username)
})
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  try {
    let url = window.location.href
    let phone = to.query.phone
    let messageCode = to.query.messageCode
    let sign = to.query.sign
    if (phone !== undefined && phone !== "undefined" && messageCode !== undefined && messageCode !== "undefined") {
      if(sign !== md5(url.split('?')[1].slice(0,url.split('?')[1].lastIndexOf('&')))){
        next({
          path: '/login',
          query: {redirect: to.fullPath}
        })
      } else {
        let params = {
          phone:phone
        }
        loginFreePassword(params).then(res =>{
          if (res.data.state =='ok') {
            store.commit('setToken',res.data.token)
            store.commit('setUser',res.data.username)
            next("/")
          } else {
            next({
              path: '/login',
              query: {redirect: to.fullPath}
            })
          }
        })
      }
    } else {
      if (to.meta.requireAuth) {// 判断该路由是否需要登录权限
        if (store.state.k) {  // 通过vuex state获取当前的token是否存在
          next();
        } else {
          next({
            path: '/login',
            query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
          })
        }
      } else {
        next();
      }
    }

  } catch (error) {
    next({
      path: '/login',
      query: {redirect: to.fullPath}
    })
  }

})

export default router
