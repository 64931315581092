<template>
  <div class="navbar-logo-container">
    <transition name="navbarLogoFade">
      <router-link  key="expand" class="navbar-logo-link" to="/business/index">
        <img :src="logo" class="navbar-logo"/>
        <h1 class="navbar-title">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NavbarLogo',
  data() {
    return {
      title: '',
      logo: require('@/assets/images/logo.png'),
      showImg:false
    }
  },
  created() {
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
.navbarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.navbarLogoFade-enter,
.navbarLogoFade-leave-to {
  opacity: 0;
}

.navbar-logo-container {
  // position: relative;
  float: left;
  height: 100%;
  text-align: center;
  overflow: hidden;

  & .navbar-logo-link {
    height: 100%;
    width: 100%;

    & .navbar-logo {
      height: 100%;
      vertical-align: middle;
    }

    & .navbar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      font-size: 26px;
      vertical-align: middle;
    }
  }

  &.collapse {
    .navbar-logo {
      margin-right: 0px;
    }
  }
}
</style>
