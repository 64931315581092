<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables.scss";

.app-main {
  /* 50= navbar  50  */
  height: calc(100vh - #{$navBarHeight});
  width: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: $backGround;
}

</style>

