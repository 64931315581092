<template>
  <div class="has-logo">
    <logo  />
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper"> -->
    <el-menu
        :default-active="activeMenu"

        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"

        mode="horizontal"
    >
      <navbar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
    </el-menu>
    <!-- </el-scrollbar> -->
    <rightmenu />
  </div>
</template>

<script>
import Logo from './Logo'
import NavbarItem from './NavbarItem'
import variables from '@/styles/variables.scss'
import Rightmenu from './Rightmenu'


export default {
  components: { NavbarItem, Logo, Rightmenu},
  data() {
    return {
      alertNote: "",
      timerCounter: 0,
      noteStyleLeft: 0,
      noteMarginLeft: 0,
      permission_routes:[
        {
          path: '/dashboard',
          redirect: '/dashboard/index',
          children: [
            {
              path: 'index',
              component: () => import('@/views/dashboard/index'),
              name: 'center',
              meta: { title: '大屏监测', icon: 'documentation',affix: true }
            }
          ]
        },
        {
          path: '/business',
          redirect: '/business/index',
          children: [
            {
              path: 'index',
              component: () => import('@/views/business/index'),
              name: 'center',
              meta: { title: '终端用户', icon: 'documentation',affix: true }
            }
          ]
        },
        {
          path: '/provider',
          redirect: '/provider/index',
          children: [
            {
              path: 'index',
              component: () => import('@/views/provider/index'),
              name: 'center',
              meta: { title: '服务商管理', icon: 'documentation',affix: true }
            }
          ]
        },
        {
          path: '/log/equalarm',
          redirect: '/log/index',
          children: [
            {
              path: 'index',
              component: () => import('@/views/log/equipment-alarm/index'),
              name: 'center',
              meta: { title: '系统日志', icon: 'documentation',affix: true }
            }
          ]
        },
        {
          path: '/datareport/sunburst',
          redirect: '/datareport/index',
          children: [
            {
              path: 'index',
              component: () => import('@/views/datareport/sunburst/index'),
              name: 'center',
              meta: { title: '数据报表', icon: 'documentation',affix: true }
            }
          ]
        },
      ]
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
    }
  },
  methods:{},
  created(){
  },
  computed: {
    activeMenu() {
      const route = this.$route

      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      if(path.substr(0,5)=='/log/'){
        return '/log/equalarm/index'
      }
      if(path.substr(0,12)=='/datareport/'){
        return '/datareport/sunburst/index'
      }
      return path

    },
    variables() {
      return variables
    }
  }
}
</script>

<style scoped>

.note-outer-wrapper{
  overflow: hidden;
  position: relative;
  padding: 0 2vw;
}
.note-inner-wrapper{
  height: 65px;
  font-size: 16px;
  color: red;
  line-height: 40px;
  margin: 0 auto;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.note-inner-wrapper span{
  position: absolute;
  top: 25%;
  height: 90%;
}
</style>