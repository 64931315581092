<template>
  <div class="app-wrapper">
    <navbar class="navbar-container" />
    <div class="main-container">
      <app-main />

    </div>
  </div>
</template>

<script>
import { AppMain, Navbar } from './components'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar
  },

  data() {
    return {
      timingProtectConfig:{
        protectTimerSwitch    : null,
        protectOneKeySwitch   : null,
        protectReleaseTime    : null,
        protectOnTime         : null,
        protectWeekendWholeDay: null,
        protectOneKeyDisableTime  : null
      }
    };
  },
  created() {
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    height: calc(100% - #{$navBarHeight});
    transition: width 0.28s;
  }

</style>
